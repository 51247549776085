import { pick } from 'lodash';
import { useGenerateSalesOrderIdMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { OrdersActions } from '@features/order/orders.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { INotPostedProduct } from './not-posted-product.interface';

type HookResult = {
  generateSalesOrderId: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useGenerateSalesOrderId(
  handleNotPostedProducts: (products: INotPostedProduct[]) => unknown,
): HookResult {
  const [mutation, flags] = useGenerateSalesOrderIdMutation();
  const dispatch = useAppDispatch();

  async function generateSalesOrderId(id: number): Promise<void> {
    const response = await mutation(id).unwrap();

    if (!isErrorResponse(response)) {
      const changes = pick(response, 'salesId');
      dispatch(OrdersActions.updateOrder({ id, changes }));
      handleNotPostedProducts(response.notPostedProducts);
    }
  }

  return { generateSalesOrderId, ...flags };
}
