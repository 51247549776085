import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const EmploymentTypes = {
  Employed: 'employed',
  Freelance: 'freelance',
} as const;

type Keys = keyof typeof EmploymentTypes;

export type EmploymentTypeType = (typeof EmploymentTypes)[Keys];

export const EmploymentTypeOptions = createEnumOptions(EmploymentTypes);
