import React, { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import { useGetAnnouncement } from './use-get-announcement';

export const useAnnouncement = (): void => {
  const { announcement } = useGetAnnouncement();

  useEffect(() => {
    const shouldShowAnnouncement = announcement?.platforms?.includes('CRM');

    if (shouldShowAnnouncement) {
      enqueueSnackbar(
        <>
          <Typography variant="h6" sx={{ mt: -0.75 }}>
            {announcement.title}
          </Typography>
          <Typography>{announcement.text}</Typography>
        </>,
        {
          variant: 'info',
          persist: true,
        },
      );
    }
  }, [announcement]);
};
