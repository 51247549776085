import React from 'react';
import { GridFooter } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AppPaper } from '@components/AppPaper';
import { AppModal } from '@components/AppModal';
import { useFilteredOrderSummaries } from './filter-order-summaries-form/use-filtered-order-summaries';
import { useFilteredAssessmentOrderSummaries } from './filter-assessment-order-summaries-form/use-filtered-assessment-order-summaries';
import { FilterOrderSummariesForm } from './filter-order-summaries-form/FilterOrderSummariesForm';
import { FilterAssessmentOrderSummariesForm } from './filter-assessment-order-summaries-form/FilterAssessmentOrderSummariesForm';
import { useAppTitle } from '@hooks/use-app-title';
import { SaveCustomerForm } from '@features/customer/save-customer-form/SaveCustomerForm';
import { UserRoles } from '@features/user/user-role.type';
import { useRole } from '@features/user/use-role';
import { CopyTableToClipboard } from '@components/CopyTableToClipboard';
import { RestrictRole } from '@features/auth/RestrictRole';
import { useToggle } from '@hooks/use-toggle';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';
import { getSortingColumnKeys } from '@features/sorting/get-sorting-column-keys';
import { useServerSorting } from '@features/sorting/use-server-sorting';
import { orderSummaryColumns } from './order-summary-columns';

const sortingKeys = getSortingColumnKeys(orderSummaryColumns);

/**
 * This component uses different logic: hooks and filter and customer creation form components based on user role.
 */
export const OrderSummariesPage: React.FC = () => {
  const role = useRole();
  useAppTitle('Orders');
  const isAssessor = role === UserRoles.assessor;
  const isQA = role === UserRoles.qualityAssurance;
  const useFilteredSummaries = isAssessor || isQA ? useFilteredAssessmentOrderSummaries : useFilteredOrderSummaries;
  const {
    entries: orderSummaries,
    totalEntries,
    paginationModel,
    onPaginationModelChange,
    isLoading,
  } = useServerPagination(useFilteredSummaries, PageSizeNames.OrderSummary);
  const { sortModel, onSortModelChange } = useServerSorting(sortingKeys);
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);

  return (
    <AppPaper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Typography variant="h4" component="h1">
          Orders
        </Typography>
        <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
          <Button variant="outlined" size="small" onClick={toggleIsModalOpen} startIcon={<AddIcon />}>
            Create Customer
          </Button>
        </RestrictRole>
      </Stack>
      <RestrictRole
        roles={[UserRoles.assessor, UserRoles.qualityAssurance]}
        AlternateComponent={<FilterAssessmentOrderSummariesForm />}
      >
        <FilterOrderSummariesForm />
      </RestrictRole>
      <AppDataGrid
        rows={orderSummaries}
        columns={orderSummaryColumns}
        loading={isLoading}
        sx={{ mt: 1 }}
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        slots={{
          footer: () => (
            <Stack direction="row" sx={{ borderTop: '1px solid rgb(224, 224, 224)' }}>
              <CopyTableToClipboard rows={orderSummaries} columns={orderSummaryColumns} />
              <GridFooter sx={{ flexGrow: 1, border: 'none' }} />
            </Stack>
          ),
        }}
      />
      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Create Customer" width="62vw">
        <SaveCustomerForm />
      </AppModal>
    </AppPaper>
  );
};
