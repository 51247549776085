import React from 'react';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppPaper } from '@components/AppPaper';
import { useAppTitle } from '@hooks/use-app-title';
import { tutorSummariesColumn } from './tutor-summaries-columns';
import { useFilteredTutorSummaries } from './filter-tutor-summaries-form/use-filtered-tutor-summaries';
import { PageSizeNames } from '@features/pagination/page-size-names';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { FilterTutorSummariesForm } from './filter-tutor-summaries-form/FilterTutorSummariesForm';

export const TutorListPage: React.FC = () => {
  const {
    entries: tutors,
    totalEntries,
    paginationModel,
    onPaginationModelChange,
    isLoading,
  } = useServerPagination(useFilteredTutorSummaries, PageSizeNames.TutorSummary);
  useAppTitle('Tutors');

  return (
    <AppPaper>
      <AppDataGrid
        title="Tutors"
        titleVariant="h4"
        Filters={<FilterTutorSummariesForm />}
        rows={tutors}
        columns={tutorSummariesColumn}
        loading={isLoading}
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
    </AppPaper>
  );
};
