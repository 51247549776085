import { Moment } from 'moment-timezone';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { ISODateString } from '@utils/dates/iso-string.type';
import { EmploymentTypeOptions } from '../employment-type.type';

export type FormModel = {
  dateFrom: Moment | null;
  dateTo: Moment | null;
  organisationId: number[];
  employmentType: number[];
  qualification: number[];
  name: string | null;
  isActive: boolean;
};

export type ApiModel = {
  dateFrom: ISODateString | null;
  dateTo: ISODateString | null;
  organisationId: number[];
  employmentType: number[];
  qualification: number[];
  name: string | null;
  isActive: boolean | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  dateFrom: { type: 'date', initial: null },
  dateTo: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  employmentType: { type: 'enum', options: EmploymentTypeOptions, multiple: true, initial: [] },
  qualification: { type: 'backend-label', optionKey: 'qualificationOptions', multiple: true, initial: [] },
  name: { type: 'transportable', initial: '' },
  isActive: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  dateFrom: Serializers.date,
  dateTo: Serializers.date,
  organisationId: Serializers.array,
  employmentType: Serializers.array,
  qualification: Serializers.array,
  name: Serializers.stringNullable,
  isActive: Serializers.booleanNullable,
});
