import React, { FC } from 'react';
import { useAssessmentCentres } from './filter-assessment-centres-form/use-assessment-centres';
import { NavLink } from 'react-router-dom';
import { createColumnFactory } from '@utils/create-column.factory';
import { AssessmentCentreSummary } from './assessment-centre.interface';
import { Box, Link, Stack, Typography } from '@mui/material';
import { Routes } from '@routes/routes';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { AppPaper } from '@components/AppPaper';
import { AppDataGrid } from '@components/AppDataGrid';
import { FilterAssessmentCentres } from './filter-assessment-centres-form/FilterAssessmentCentres';
import { EmptyCell } from '@utils/empty-cell';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';
import { useAppTitle } from '@hooks/use-app-title';

const createAssessmentCentreColumn = createColumnFactory<AssessmentCentreSummary>();

const columns = [
  createAssessmentCentreColumn('id', {
    headerName: 'Id',
    width: 45,
    renderCell: ({ row: assessmentCentre }) => (
      <Link component={NavLink} to={`${Routes.assessmentCentres}/${assessmentCentre.id}`}>
        {assessmentCentre.id}
      </Link>
    ),
  }),
  createAssessmentCentreColumn('name', {
    headerName: 'Name',
    width: 150,
  }),
  createAssessmentCentreColumn('city', {
    headerName: 'City',
    width: 150,
  }),
  createAssessmentCentreColumn('postcode', {
    headerName: 'Postcode',
    width: 150,
  }),
  createAssessmentCentreColumn('distance', {
    headerName: 'Distance',
    width: 80,
    renderCell: ({ row }) => (row.distance ? `${row.distance} km` : EmptyCell),
  }),
  createAssessmentCentreColumn('assessorIds', {
    headerName: 'Assessors',
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Stack direction="row" spacing={1}>
          {row.assessorIds.map((item) => (
            <Link key={item} component={NavLink} to={`${Routes.assessors}/${item}`}>
              <BackendLabel value={item} optionKey="assessorOptions" isPlainText />
            </Link>
          ))}
        </Stack>
      );
    },
  }),
];

export const AssessmentCentreListPage: FC = () => {
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = useServerPagination(
    useAssessmentCentres,
    PageSizeNames.AssessmentCentre,
  );
  useAppTitle('Assessment Centres');

  return (
    <AppPaper>
      <Stack direction="row">
        <Typography variant="h4" component="h1">
          Assessment Centres
        </Typography>
        <Box sx={{ flexGrow: 1, ml: 2 }}>
          <FilterAssessmentCentres />
        </Box>
      </Stack>
      <AppDataGrid
        rows={entries}
        columns={columns}
        loading={isLoading}
        sx={{ mt: 1 }}
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
    </AppPaper>
  );
};
