import { SharedProps } from 'notistack';
import { isObjectWithKeys } from '@utils/is-object-with-keys';

type Notification = {
  text: string;
} & SharedProps;

export interface INotificationResponse {
  notification: Notification;
}

export function isNotificationResponse(response: unknown): response is INotificationResponse {
  return (
    isObjectWithKeys<INotificationResponse>(response, ['notification']) &&
    isObjectWithKeys<Notification>(response.notification, ['text']) &&
    typeof response.notification.text === 'string'
  );
}
