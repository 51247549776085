export const PageSizeNames = {
  ActionRecord: 'action-record-page-size',
  Allocation: 'allocation-page-size',
  AppointmentSummary: 'appointment-summary-page-size',
  AppointmentInvoiceSummary: 'appointment-invoice-summary-page-size',
  SessionInvoiceSummary: 'session-invoice-summary-page-size',
  EquipmentInvoiceSummary: 'equipment-invoice-summary-page-size',
  AssessmentCentre: 'assessment-centre-page-size',
  AssessorFeedback: 'assessor-feedback-page-size',
  Assessor: 'assessor-page-size',
  EquipmentInvoice: 'equipment-invoice-page-size',
  Heps: 'heps-page-size',
  OrderSummary: 'order-summary-page-size',
  Product: 'product-page-size',
  Quote: 'quote-page-size',
  Session: 'session-page-size',
  TutorFeedback: 'tutor-feedback-page-size',
  TutorSummary: 'tutor-summary-page-size',
} as const;
