import { useCallback } from 'react';
import { useCreateOrResetAccountMutation } from '@api/api-slice';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = {
  submitHandler(customerId: number): Promise<void>;
} & RequestStatusFlags;

export function useCreateOrResetAccount(): HookResult {
  const [mutation, flags] = useCreateOrResetAccountMutation();

  const submitHandler = useCallback(
    async (customerId: number): Promise<void> => {
      await mutation(customerId).unwrap();
    },
    [mutation],
  );

  return {
    ...mergeRequestStatusFlags(flags),
    submitHandler,
  };
}
