import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse } from '@api/error-response.interface';
import { IOrderTechnical } from '@features/order/technical/order-technical.interface';

export type Response = {
  invoiceNumber: IOrderTechnical['invoiceNumber'];
};

export const buildGenerateInvoiceNumber = appMutationFactory<number, Response | IErrorResponse>({
  query: (orderId) => ({
    url: EndpointUrlBuilder.generateTechnicalOrderInvoiceNumber(orderId),
    method: 'POST',
  }),
});
