import { pick } from 'lodash';
import { useGenerateInvoiceNumberMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { OrdersActions } from '@features/order/orders.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = {
  generateInvoiceNumber: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useGenerateInvoiceNumber(): HookResult {
  const [mutation, flags] = useGenerateInvoiceNumberMutation();
  const dispatch = useAppDispatch();

  async function generateInvoiceNumber(id: number): Promise<void> {
    const response = await mutation(id).unwrap();

    if (!isErrorResponse(response)) {
      const changes = pick(response, 'invoiceNumber');
      dispatch(OrdersActions.updateOrder({ id, changes }));
    }
  }

  return { generateInvoiceNumber, ...flags };
}
