import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetTutorSummariesQuery } from '@api/api-slice';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { TutorSummary } from '../tutor.interface';
import { sanitize, serialize } from './form-config';

type HookResult = RequestStatusFlags & IPaginable<TutorSummary>;

export function useFilteredTutorSummaries(urlSearchParams: URLSearchParams, count: number, skip: number): HookResult {
  const backendLabels = useAppBackendLabels(['qualificationOptions']);
  const params = serialize(sanitize(deserializeURLSearchParams(urlSearchParams), backendLabels));
  return getPaginationResult(useGetTutorSummariesQuery({ ...params, count, skip }));
}
