import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useSaveAnnualSupportCharge } from './use-save-annual-support-charge';
import { IAnnualSupportCharge } from '../annual-support-charge-list/annual-support-charge.interface';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { NumberPattern } from '@utils/validation-rules';

type Props = {
  annualSupportCharge: IAnnualSupportCharge | void;
  orderId: number;
  onClose: () => void;
};

export const SaveAnnualSupportChargeForm: FC<Props> = ({ annualSupportCharge, orderId, onClose }) => {
  const { submitHandler, isSaveEnabled, ascCancellationReasonOptions, isLabelsLoading, buttonText, control } =
    useSaveAnnualSupportCharge(annualSupportCharge, orderId, onClose);

  return (
    <form onSubmit={submitHandler}>
      <Stack direction="column" spacing={2}>
        <ControlledTextField
          control={control}
          name="numberOfSupportYear"
          label="Support Year"
          inputProps={{ inputMode: 'numeric' }}
          rules={{ pattern: NumberPattern }}
          required
        />
        <ControlledTextField
          control={control}
          name="annualSupportExVat"
          label="Gross Cost (inc VAT)"
          inputProps={{ inputMode: 'numeric' }}
          required
        />
        <ControlledTextField
          control={control}
          name="annualSupportInclVat"
          label="Gross Cost (inc VAT)"
          inputProps={{ inputMode: 'numeric' }}
          required
        />
        <ControlledDatePicker control={control} name="billingDate" label="Billing Date" disablePast required />
        <ControlledToggleButton
          control={control}
          name="isCancelled"
          getLabel={(value: boolean): string => (value ? 'Cancelled' : 'Not Cancelled')}
        />
        <ControlledAutocomplete
          control={control}
          name="cancellationReason"
          options={ascCancellationReasonOptions}
          label="Cancellation Reason"
          isLoading={isLabelsLoading}
        />
        <ControlledTextField control={control} name="funderInvoice" label="Invoice" multiline />
        <Button variant="contained" size="large" type="submit" disabled={!isSaveEnabled}>
          {buttonText}
        </Button>
      </Stack>
    </form>
  );
};
