import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse } from '@api/error-response.interface';
import { INotificationResponse } from '@api/notification-response.interface';

export const buildCreateOrResetAccount = appMutationFactory<number, IErrorResponse | INotificationResponse>({
  query: (customerId) => ({
    url: EndpointUrlBuilder.createOrResetAccount(customerId),
    method: 'POST',
  }),
});
