import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link, Stack, Typography } from '@mui/material';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppPaper } from '@components/AppPaper';
import { TrafficLightCell } from '@components/cells/TrafficLightCell';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { PageSizeNames } from '@features/pagination/page-size-names';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { useRole } from '@features/user/use-role';
import { UserRoles } from '@features/user/user-role.type';
import { useAppTitle } from '@hooks/use-app-title';
import { Routes } from '@routes/routes';
import { createColumnFactory } from '@utils/create-column.factory';
import { BooleanChip } from '@utils/render-boolean';
import { FilterProductsForm } from './filter-products-form/FilterProductsForm';
import { useFilteredProducts } from './filter-products-form/use-filtered-products';
import { IProduct } from './product.interface';

const AllowedAssessorColumnNames: Array<keyof IProduct> = [
  'id',
  'productCode',
  'productCategoryId',
  'productName',
  'isActive',
  'amountExVat',
  'productCodeAs',
  'productCodeSst',
  'vatTreatment',
  'trafficLight',
];

const createProductColumn = createColumnFactory<IProduct>();
const columns = [
  createProductColumn('id', {
    headerName: 'Id',
    width: 45,
    renderCell: ({ row: product }) => (
      <Link component={NavLink} to={`${Routes.products}/${product.id}`}>
        {product.id}
      </Link>
    ),
  }),
  createProductColumn('productCode', {
    headerName: 'Product Code',
    width: 130,
  }),
  createProductColumn('productCategoryId', {
    headerName: 'Product Category',
    width: 320,
    renderCell: ({ row }) => <BackendLabel value={row.productCategoryId} optionKey="productCategory" />,
  }),
  createProductColumn('productName', {
    headerName: 'Product Name',
    width: 500,
  }),
  createProductColumn('isActive', {
    headerName: 'Active',
    width: 80,
    renderCell: ({ row }) => <BooleanChip value={row.isActive} />,
  }),
  createProductColumn('amountExVat', {
    headerName: 'Net Amount',
    width: 130,
  }),
  createProductColumn('feedbackAverage', {
    headerName: 'Feedback Average',
    width: 130,
  }),
  createProductColumn('productCodeAs', {
    headerName: 'Product code AS',
    width: 130,
  }),
  createProductColumn('productCodeSst', {
    headerName: 'Product code SST',
    width: 130,
  }),
  createProductColumn('vatTreatment', {
    headerName: 'Treatment',
    width: 100,
  }),
  createProductColumn('trafficLight', {
    headerName: 'Traffic Light',
    width: 120,
    renderCell: ({ row: { trafficLight } }) => <TrafficLightCell trafficLight={trafficLight} />,
  }),
];

export const ProductListPage: React.FC = () => {
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = useServerPagination(
    useFilteredProducts,
    PageSizeNames.Product,
  );
  const role = useRole();
  const isAssessor = role === UserRoles.assessor;
  useAppTitle('Products');
  const filteredColumns = useMemo(() => {
    return isAssessor
      ? columns.filter((item) => AllowedAssessorColumnNames.includes(item.field as keyof IProduct))
      : columns;
  }, [isAssessor]);

  return (
    <AppPaper>
      <Stack direction="row" alignItems="center">
        <Typography variant="h4" component="h1">
          Products
        </Typography>
        <Box sx={{ flexGrow: 1, ml: 2 }}>
          <FilterProductsForm />
        </Box>
      </Stack>
      <AppDataGrid
        rows={entries}
        columns={filteredColumns}
        loading={isLoading}
        sx={{ mt: 1 }}
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
    </AppPaper>
  );
};
