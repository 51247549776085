import React from 'react';
import { Chip } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import { ColorByTrafficLight, LabelByTrafficLight, TrafficLightType } from '@models/traffic-light.type';
import { EmptyCell } from '@utils/empty-cell';

type Props = {
  trafficLight: TrafficLightType | null;
};

export const TrafficLightCell: React.FC<Props> = ({ trafficLight }) => {
  return trafficLight ? (
    <Chip
      label={LabelByTrafficLight[trafficLight]}
      color={ColorByTrafficLight[trafficLight]}
      icon={<LightModeIcon />}
      variant="outlined"
      size="small"
    />
  ) : (
    <>{EmptyCell}</>
  );
};
