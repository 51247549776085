import React from 'react';
import { CustomContentProps, SnackbarContent, closeSnackbar } from 'notistack';
import { Alert } from '@mui/material';

export const AppSnackbar = React.forwardRef<HTMLDivElement, CustomContentProps>(({ message, id, variant }, ref) => {
  const severity = variant === 'default' ? 'info' : variant;

  return (
    <SnackbarContent ref={ref}>
      <Alert severity={severity} variant="filled" sx={{ width: '100%' }} onClose={(): void => closeSnackbar(id)}>
        {message}
      </Alert>
    </SnackbarContent>
  );
});

AppSnackbar.displayName = 'AppSnackbar';
