import React, { useCallback, useMemo, useState } from 'react';
import { ActionColumnKey, createColumnFactory } from '@utils/create-column.factory';
import { AppDataGrid } from '@components/AppDataGrid';
import { Button, Stack, Typography } from '@mui/material';
import { IQuoteDetail } from '@features/quote/quote.interface';
import { AppModal } from '@components/AppModal';
import { CopyTableToClipboard } from '@components/CopyTableToClipboard';
import { useToggle } from '@hooks/use-toggle';
import { IProductSummary } from '../product.interface';
import { TotalId, createTotalRow } from './create-total-row';
import { TrafficLightCell } from '@components/cells/TrafficLightCell';

type EditableProps = {
  products: IProductSummary[];
  quote: IQuoteDetail | null;
  onDeleteProduct: (id: number) => unknown;
  isEditable?: boolean;
  isCompact?: boolean;
};

type NonEditableProps = {
  products: IProductSummary[];
  quote: IQuoteDetail | null;
  onDeleteProduct?: never;
  isEditable: false;
  isCompact?: boolean;
};

type Props = EditableProps | NonEditableProps;

const createProductColumn = createColumnFactory<IProductSummary>();

const BaseColumns = [
  createProductColumn('productName', {
    headerName: 'Item',
    flex: 3,
  }),
  createProductColumn('quantity', {
    headerName: 'Quantity',
    width: 80,
  }),
  createProductColumn('productCode', {
    headerName: 'Product Code',
    flex: 1,
  }),
  createProductColumn('productCodeAs', {
    headerName: 'Product code AS',
    flex: 1,
    isHiddenInCompactView: true,
  }),
  createProductColumn('productCodeSst', {
    headerName: 'Product code SST',
    flex: 1,
    isHiddenInCompactView: true,
  }),
  createProductColumn('amountExVat', {
    headerName: 'Ex VAT',
    width: 80,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
  }),
  createProductColumn('amountInclVat', {
    headerName: 'Inc VAT',
    width: 80,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
  }),
  createProductColumn('trafficLight', {
    headerName: 'Traffic Light',
    width: 120,
    renderCell: ({ row: { trafficLight } }) => <TrafficLightCell trafficLight={trafficLight} />,
  }),
];

export const ProductTable: React.FC<Props> = ({
  products,
  quote,
  onDeleteProduct,
  isEditable = true,
  isCompact = false,
}) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [productId, setProductId] = useState<number | null>(null);

  const handleDeleteProduct = useCallback(
    (value: number): void => {
      setProductId(value);
      toggleIsModalOpen();
    },
    [toggleIsModalOpen],
  );

  const handleConfirm = useCallback((): void => {
    toggleIsModalOpen();
    if (productId && onDeleteProduct) {
      onDeleteProduct(productId);
    }
    setProductId(null);
  }, [onDeleteProduct, productId, toggleIsModalOpen]);

  const columns = useMemo(() => {
    const actionColumnDefinition = createProductColumn(ActionColumnKey, {
      headerName: 'Actions',
      width: 100,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ row }) =>
        row.id !== TotalId && (
          <Button onClick={(): void => handleDeleteProduct(row.id)} variant="outlined" size="small" color="error">
            Delete
          </Button>
        ),
    });
    return isEditable ? BaseColumns.concat(actionColumnDefinition) : BaseColumns;
  }, [isEditable, handleDeleteProduct]);

  const enhancedWithTotal = quote ? products.concat(createTotalRow(quote)) : products;

  return (
    <>
      <AppDataGrid
        title="Quote Products"
        rows={enhancedWithTotal}
        columns={columns}
        sx={{ mt: 4 }}
        autoHeight={isCompact}
        hideFooter={isCompact}
        hideFooterPagination={!isCompact}
        slots={{
          footer: () => (isCompact ? null : <CopyTableToClipboard rows={products} columns={columns} />),
        }}
      />
      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Delete Quote Product?">
        <>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Are you sure you want to delete this Quote Product?
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={handleConfirm} color="error">
              Delete
            </Button>
            <Button variant="text" onClick={toggleIsModalOpen}>
              Keep
            </Button>
          </Stack>
        </>
      </AppModal>
    </>
  );
};
