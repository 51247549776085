import { isObjectWithKeys } from '@utils/is-object-with-keys';
import { isNotificationResponse } from './notification-response.interface';

export interface IFieldError {
  name: string;
  message: string;
}

export interface IErrorResponse {
  notification: Notification & { fields: IFieldError[] };
}

export function isErrorResponse(response: unknown): response is IErrorResponse {
  return (
    isNotificationResponse(response) &&
    isObjectWithKeys<IErrorResponse>(response, ['notification']) &&
    isObjectWithKeys<IErrorResponse>(response.notification, ['fields']) &&
    Array.isArray(response.notification.fields) &&
    response.notification.fields.every(
      (field: unknown) =>
        isObjectWithKeys<IFieldError>(field, ['message', 'name']) &&
        typeof field.name === 'string' &&
        typeof field.message === 'string',
    )
  );
}
