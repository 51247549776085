import React, { PropsWithChildren } from 'react';
import { SnackbarProvider } from 'notistack';
import { AppSnackbar } from './AppSnackbar';

export const AppSnackbarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={5000}
      variant="error"
      preventDuplicate
      Components={{
        default: AppSnackbar,
        error: AppSnackbar,
        success: AppSnackbar,
        warning: AppSnackbar,
        info: AppSnackbar,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
