import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MUIProvider } from '@context/MUIProvider';
import { PreviewPDFProvider } from '@context/PreviewPDF';
import { persistor, store } from '@store/store';
import { Router } from '@routes/Router';
import { AppSnackbarProvider } from '@features/snackbar/AppSnackbarProvider';

const queryClient = new QueryClient();

// loading null to create preloader for rehydrating persist store
export async function initApp(appRootNode: HTMLElement): Promise<void> {
  const root = createRoot(appRootNode);

  const renderApp = (): void =>
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MUIProvider>
              <QueryClientProvider client={queryClient}>
                <PreviewPDFProvider>
                  <AppSnackbarProvider>
                    <Router />
                  </AppSnackbarProvider>
                </PreviewPDFProvider>
              </QueryClientProvider>
            </MUIProvider>
          </PersistGate>
        </Provider>
      </React.StrictMode>,
    );

  renderApp();

  if (module.hot) {
    module.hot.accept('@routes/Router', renderApp);
  }
}
