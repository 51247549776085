import React, { useMemo, useState } from 'react';
import { IQuoteDetail } from './quote.interface';
import { Box, Chip, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { IJustificationText, IJustificationTextPart } from './justification-text.interface';
import { uniq } from 'lodash';
import { CopyToClipboard } from '@components/CopyToClipboard';

type Props = {
  quote: IQuoteDetail;
};

export const QuoteRecommendationTexts: React.FC<Props> = ({ quote }) => {
  const slugs = useMemo(() => uniqueSlugs(quote.justificationTexts), [quote.justificationTexts]);
  const [activeSlug, setActiveSlug] = useState<string | null>(null);

  const filteredProductJustifications = useMemo(
    () =>
      quote.justificationTexts.map(({ productName, texts }) => ({
        productName,
        texts: printTexts(texts, activeSlug),
      })),
    [activeSlug, quote.justificationTexts],
  );

  const textToCopy = useMemo(
    () => createCopyableText(filteredProductJustifications) ?? '',
    [filteredProductJustifications],
  );

  if (quote.justificationTexts.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Stack spacing={2} direction="row">
        <Typography variant="h5" component="h2">
          Justification Texts
        </Typography>
        <CopyToClipboard textToCopy={textToCopy} label="Copy Selected Justification Texts" />
      </Stack>

      <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
        {[null, ...slugs].map((slug) => (
          <Chip
            key={slug}
            label={slug === null ? 'All' : slug}
            color={activeSlug === slug ? 'primary' : 'default'}
            icon={activeSlug === slug ? <CheckIcon sx={{ fontSize: '16px' }} /> : undefined}
            onClick={(): void => setActiveSlug(slug)}
          />
        ))}
      </Stack>

      {filteredProductJustifications.map(({ productName, texts }, index) => (
        <React.Fragment key={`quote-recommendation-text-${index}`}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 1 }}>
            {productName}
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 1, whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: texts }}
          />
        </React.Fragment>
      ))}
    </Box>
  );
};

function uniqueSlugs(justificationTexts: IJustificationText[]): string[] {
  return uniq(
    justificationTexts.map(({ texts }) => texts.map(({ slug }) => slug).filter(Boolean)).flat(),
  ).sort() as string[];
}

function printTexts(texts: IJustificationTextPart[], activeSlug: string | null): string {
  return texts
    .filter(({ slug }) => (activeSlug !== null ? slug === activeSlug : true))
    .map(({ title, text }) => `${wrapTitle(title)}${text}`)
    .join('\n');
}

function wrapTitle(title: string | null): string {
  return title ? `<strong>${title}</strong> ` : '';
}

function createCopyableText(
  filteredProductJustifications: {
    productName: string;
    texts: string;
  }[],
): string | null {
  const node = document.createElement('div');
  node.innerHTML = filteredProductJustifications.map(({ productName, texts }) => `${productName}\n${texts}`).join('\n');
  return node.textContent;
}
