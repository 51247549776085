import React from 'react';
import { Chip } from '@mui/material';
import { EmptyCell } from '@utils/empty-cell';
import { Result } from './bulk-action-row-result.type';
import { isNil } from 'lodash';

type Props = {
  result: Result | null;
};

export const ResultCell: React.FC<Props> = ({ result }) => {
  if (!result) {
    return <span>{EmptyCell}</span>;
  }

  const fallbackLabel = result.isSuccess ? 'Accepted' : 'Rejected';
  const label = isNil(result.message) ? fallbackLabel : result.message;

  return (
    <Chip
      label={label}
      size="small"
      variant="outlined"
      color={result.isSuccess ? 'success' : 'error'}
      className="MuiChip-status MuiChip-multiline"
    />
  );
};
