import { useGetTutorQuery } from '@api/api-slice';
import { ITutorDetails } from '@features/tutors/tutor.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { RouteParamsType } from '@routes/route-params.type';
import { useParams } from 'react-router-dom';

type HookResult = RequestStatusFlags & {
  tutor: ITutorDetails | null;
};

export function useTutor(): HookResult {
  const { tutorIdString } = useParams<RouteParamsType>();
  // the tutor id is always exist since the hook used on tutor route
  const tutorId = parseInt(tutorIdString as string, 10);
  const { data, ...flags } = useGetTutorQuery(tutorId);
  return {
    tutor: data?.tutor ?? null,
    ...flags,
  };
}
