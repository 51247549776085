import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { ITutorDetails } from '@features/tutors/tutor.interface';
import { TransportableModel } from '@models/transportable.type';
import { ITutorFeedbackSummary } from './tutor-feedback-summary.interface';

// TODO сразу ITutor
interface ITutorResponse {
  tutor: ITutorDetails;
}

export const buildGetTutor = appQueryFactory<number, ITutorResponse>({
  query: (id) => ({
    url: EndpointUrlBuilder.getTutor(id),
    method: 'GET',
  }),
});

type TutorFeedbackSummariesQueryParams = TransportableModel<{ tutorId: number } & PaginationArgs>;
type TutorFeedbackSummariesResponse = IPaginable<ITutorFeedbackSummary>;
export const buildGetTutorFeedbackSummaries = appQueryFactory<
  TutorFeedbackSummariesQueryParams,
  TutorFeedbackSummariesResponse
>({
  query: ({ tutorId, ...params }) => ({
    url: EndpointUrlBuilder.getTutorFeedbackSummaries(tutorId),
    method: 'GET',
    params,
  }),
});
