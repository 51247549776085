import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TutorSummary } from '@features/tutors/tutor.interface';
import { TransportableModel } from '@models/transportable.type';
import { ApiModel } from './form-config';

export type TutorSummariesParams = TransportableModel<ApiModel & PaginationArgs>;
export type TutorSummariesResponse = IPaginable<TutorSummary>;
export const buildGetTutorSummaries = appQueryFactory<TutorSummariesParams, TutorSummariesResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.getTutorSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse: (response: TutorSummariesResponse) => {
    // validateTutorSummaries(response, EndpointUrlBuilder.getTutorSummaries());
    return response;
  },
});
