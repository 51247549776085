import React, { useMemo } from 'react';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useToggle } from '@hooks/use-toggle';
import { ExtendedGridColDef } from '@utils/create-column.factory';
import { Variant } from '@mui/material/styles/createTypography';
import { isNil } from 'lodash';

interface IProps extends DataGridProps {
  columns: ExtendedGridColDef[];
  title?: string;
  titleVariant?: Variant;
  /** @deprecated */
  fullHeight?: boolean;
  Filters?: JSX.Element;
}

export const AppDataGrid: React.FC<IProps> = ({ columns, title, titleVariant = 'h5', Filters, sx, ...props }) => {
  const [isExtended, toggleIsExtended] = useToggle();

  const filteredColumns = useMemo(
    () => columns.filter((colDef) => isExtended || !colDef.isHiddenInCompactView),
    [columns, isExtended],
  );

  const isExtendButtonVisible = columns.some((colDef) => colDef.isHiddenInCompactView === true);
  const isTitleVisible = title !== undefined;
  const isHeaderVisible = isExtendButtonVisible || isTitleVisible;
  const isFiltersVisible = !isNil(Filters);

  // remove column separators if is compact
  const slots = props.slots ?? {};
  const isCompact = props.density !== 'comfortable' && props.density !== 'standard';

  if (isCompact) {
    slots.columnResizeIcon = (): JSX.Element | null => null;
  }

  return (
    <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', flexDirection: 'column', ...sx }}>
      {isHeaderVisible && (
        <Stack direction="row" alignItems="center">
          {isTitleVisible && <Typography variant={titleVariant}>{title}</Typography>}
          {isExtendButtonVisible && (
            <Button sx={{ ml: 1 }} variant="outlined" size="small" onClick={toggleIsExtended}>
              {isExtended ? 'Show Less' : 'Show More'}
            </Button>
          )}
        </Stack>
      )}
      {isFiltersVisible && <Box sx={{ mt: isHeaderVisible ? 1 : 0 }}>{Filters}</Box>}
      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          density="compact"
          sx={{ mt: isHeaderVisible || isFiltersVisible ? 1 : 0 }}
          disableRowSelectionOnClick
          pageSizeOptions={[10, 15, 25, 50, 100]}
          hideFooter
          slots={slots}
          columns={filteredColumns}
          {...props}
        />
      </Box>
    </Box>
  );
};
